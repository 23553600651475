import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MyBrandMap } from '../MyBrandMap';
import { MyBrandMapCard } from '../MyBrandMapCard';
import { OtherThemeList } from './-OtherThemeList';
import { MainThemeList } from './-MainThemeList';

import {
  HotelBasicInfo,
  HotelMasterList,
  HotelBrandDetail,
  NavigationBaseProps,
} from '~/schemes';

export interface HotelBasicInfoWithLocation extends HotelBasicInfo {
  location: NavigationBaseProps;
}

export interface MyBrandHotelProps {
  hotels: HotelBasicInfo[];
  groupedByBrandType: HotelMasterList[];
  data: HotelBrandDetail;
}

export interface MyBrandHotelEmits {}

export interface MyBrandHotelScopedSlots {}

@Component<MyBrandHotelRef>({
  name: 'MyBrandHotel',
  render() {
    const {
      computedHotels: hotels,
      computedHotelBasicInfoList: hotelBasicInfoList,
      brand,
      selectedHotelId,
      activeSlideHotelId,
    } = this;

    return (
      <div staticClass="my-hotels">
        <h2 staticClass="my-hotels__title">{this.$t('label.listOfHotels')}</h2>
        {!this.$theme.is('daytrip') && (
          <div staticClass="my-hotels__map-wrapper">
            <MyBrandMap
              hotels={hotels}
              hotelMasterList={this.hotelMasterList}
              activeSlideHotelId={activeSlideHotelId}
              onSelectedHotelId={this.receivedSelectedHotelId}
            />
            <MyBrandMapCard
              data={brand}
              hotelBasicInfoList={hotelBasicInfoList}
              selectedHotelId={selectedHotelId}
              onChangeHotelBasicInfo={this.handleActiveSlideChange}
            />
          </div>
        )}
        <div staticClass="my-hotels__list-wrapper">
          {this.isOtherTheme() ? (
            <OtherThemeList hotels={hotels} />
          ) : (
            <MainThemeList hotels={hotels} />
          )}
        </div>
      </div>
    );
  },
})
export default class MyBrandHotelRef extends Vue implements MyBrandHotelProps {
  @Prop({ type: Array, required: true }) readonly hotels!: HotelBasicInfo[];
  @Prop({ type: Array, required: true })
  readonly groupedByBrandType!: HotelMasterList[];

  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;

  /** マップで選択された施設情報 */
  selectedHotel: HotelBasicInfo | null = null;

  /** マップで選択した施設のhotelId */
  private selectedHotelId = '';
  /** スワイプでactiveになっている施設のhotelId */
  private activeSlideHotelId = '';

  get computedHotels() {
    return this.hotels
      .map((hotel) => {
        return {
          ...hotel,
          location: this.$hotel.toNavigationProps(hotel)!,
        };
      })
      .filter((h) => !!h.location);
  }

  get brand() {
    return this.data;
  }

  /** 横断検索マスタから、CMSで登録された施設一覧に存在する施設情報を取得 */
  get hotelMasterList() {
    /** 施設一覧に表示されている施設のhotelIdを取得 */
    const linkedHotelsId = this.hotels.map((hotel) => hotel.ygetsId);
    return this.groupedByBrandType.filter((hotel) =>
      linkedHotelsId.includes(hotel.hotelId),
    );
  }

  /** 横断検索マスタと施設一覧両方に存在する施設のCMSで登録されたhotelBasicInfoListを取得 */
  get hotelBasicInfoList() {
    const hotelBasicInfoList = this.hotels.filter((hotel) => {
      if (!hotel.ygetsId) return null;
      return this.ids.includes(hotel.ygetsId);
    });
    return hotelBasicInfoList;
  }

  get ids(): string[] {
    return this.hotelMasterList.map((hotel) => hotel.hotelId);
  }

  /** locationを追加するために追加 */
  get computedHotelBasicInfoList() {
    return this.hotelBasicInfoList
      .map((hotel) => {
        const matchedHotelId:
          | HotelMasterList
          | undefined = this.hotelMasterList.find(
          (val) => val.hotelId === hotel.ygetsId,
        );

        return {
          ...hotel,
          location: this.$hotel.toNavigationProps(hotel)!,
          baseRate: matchedHotelId ? matchedHotelId.quote : '',
        };
      })
      .filter((h) => !!h.location);
  }

  /** テーマが「そのほかの個性的なホテル」または「日帰り施設」かどうかを返却 */
  private isOtherTheme(): boolean {
    return this.$theme.is('uniquehotels') || this.$theme.is('daytrip');
  }

  receivedSelectedHotelId(selectedHotelId: string) {
    this.selectedHotelId = selectedHotelId;
  }

  /** swiperでactiveになっているhotelIdを取得 */
  handleActiveSlideChange(activeSlide) {
    this.activeSlideHotelId = activeSlide;
  }
}

export const TypedMyBrandHotel = tsx
  .ofType<MyBrandHotelProps, MyBrandHotelEmits, MyBrandHotelScopedSlots>()
  .convert(MyBrandHotelRef);
