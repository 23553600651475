import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as tsx from 'vue-tsx-support';
import {
  HotelMasterList,
  HotelBasicInfo,
  initialDisplayHotel,
  INITIAL_DISPLAY_HOTELS,
} from '~/schemes';

export interface MyBrandMapProps {
  hotels: HotelBasicInfo[];
  hotelMasterList: HotelMasterList[];
  activeSlideHotelId: string;
}

export interface MyBrandMapEmits {
  onSelectedHotelId: string;
}

export interface MyBrandMapScopedSlots {}

@Component<MyBrandMapRef>({
  name: 'MyBrandMap',
})
export default class MyBrandMapRef extends Vue implements MyBrandMapProps {
  @Prop({ type: Array }) readonly hotels!: HotelBasicInfo[];
  @Prop({ type: Array }) readonly hotelMasterList!: HotelMasterList[];
  @Prop({ type: String }) readonly activeSlideHotelId!: string;

  /** DOMの読み込みで、指定の要素が読みこまれたかの判定 */
  isInfoWindowReady = false;

  /** 施設ID初期値 */
  private initialHotelId =
    INITIAL_DISPLAY_HOTELS[this.$theme.current.name as initialDisplayHotel];

  /** 施設情報初期値 */
  private initialHotel = this.hotels.find(
    (val) => this.initialHotelId === val.ygetsId,
  );

  /** 選択した施設の施設ID */
  private selectedHotelId?: string = this.initialHotelId;

  /** 選択されている施設の施設情報 */
  selectedHotel?: HotelBasicInfo = this.initialHotel;

  mounted() {
    // 初期表示設定
    this.$emit('selectedHotelId', this.initialHotelId);

    // gmap-info-windowが描画されてから施設名表示が表示されるように制御
    this.$nextTick(() => {
      if (this.$refs.infoWindow) {
        this.isInfoWindowReady = true;
      }
    });
  }

  /** 地図表示の中心を設定 @comment スマートな書き方があればそっちにしたい */
  get center() {
    return this.hotelMasterList.find(
      (hotel) =>
        hotel.hotelId ===
        INITIAL_DISPLAY_HOTELS[this.$theme.current.name as initialDisplayHotel],
    )?.geolocation;
  }

  /**
   * Google Mapのオプション設定。プロパティの説明は以下URLを参照。
   * {@link https://developers.google.com/maps/documentation/javascript/controls?hl=ja}
   * */
  get gmapOptions() {
    return {
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false,
      styles: [{ stylers: [{ saturation: -60, lightness: -50 }] }],
    };
  }

  /** swiperでactiveになっている施設のhotelIdを監視 */
  @Watch('activeSlideHotelId')
  onChangeSlide(activeSlideHotelId: string) {
    this.selectedHotelId = activeSlideHotelId;
  }

  /**
   * クリックされたアイコンを非表示にするアイコンの表示非表示処理
   * @params {string} hotelId
   * @returns {string} google mapのiframe要素
   */
  getCircleIcon(id: string) {
    return this.selectedHotelId === id
      ? { url: require('./transparent-pin.svg') }
      : { url: require('./green-pin.svg') };
  }

  /** 選択された施設のhotelIdセットし、親コンポーネントに送信 */
  setSelectHotel(hotel: HotelMasterList) {
    this.selectedHotelId = hotel.hotelId;
    this.$emit('selectedHotelId', this.selectedHotelId);
  }

  /** CMSで登録されたホテルの名称を表示 */
  getHotelName(hotelId: string) {
    const hotel = this.hotels.find((hotel) => hotel.ygetsId === hotelId);
    return hotel ? hotel.name : '';
  }
}

export const TypedMyBrandMap = tsx
  .ofType<MyBrandMapProps, MyBrandMapEmits, MyBrandMapScopedSlots>()
  .convert(MyBrandMapRef);
