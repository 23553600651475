import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { HotelBrandDetail } from '~/schemes';
import { BrandConceptMovie } from '~/schemes/brand';

import { HBackdrop, HVideoPlayer } from '~/components';

export interface MyBrandConceptMovieProps {
  conceptMovie: BrandConceptMovie;
}

export interface MyBrandConceptMovieEmits {}

export interface MyBrandConceptMovieScopedSlots {}

@Component<MyBrandConceptMovieRef>({
  name: 'MyBrandConceptMovie',
  render() {
    const { conceptMovie, title } = this;

    return (
      <div staticClass="my-header__concept-movie">
        {!this.showVideo && (
          <div
            staticClass="my-header__concept-movie__button"
            onClick={this.toggle}>
            <div staticClass="my-header__concept-movie__thumbnail">
              {/* 動画のposter */}
              <img
                src={conceptMovie!.videoPoster!}
                class="my-header__concept-movie__thumbnail__poster"
                alt={title}
              />
              {/* プレイヤーマーク */}
              <img
                class="my-header__concept-movie__thumbnail__play_circle"
                src={this.$res.img('common/play_circle.svg')}
              />
            </div>
            {/* タイトル */}
            {title && (
              <div staticClass="my-header__concept-movie__title">{title}</div>
            )}
          </div>
        )}
        {/* 動画 */}
        <HBackdrop
          v-model={this.showVideo}
          darken
          scopedSlots={{
            default: () => (
              <HVideoPlayer
                staticClass="my-hotel-feature-concept-movie__video"
                controls
                src={conceptMovie!.video}
                muted
                autoplay
                loop
              />
            ),
          }}
        />
      </div>
    );
  },
})
export default class MyBrandConceptMovieRef
  extends Vue
  implements MyBrandConceptMovieProps {
  @Prop({ type: Object, required: true })
  readonly conceptMovie!: BrandConceptMovie;

  /** コンセプトムービーの表示制御 */
  private showVideo = false;

  private toggle() {
    this.showVideo = !this.showVideo;
  }

  /** コンセプトムービータイトル */
  get title(): string {
    if (!this.conceptMovie) return '';
    const { title } = this.conceptMovie;
    if (!title) return '';
    return this.$language.current === 'ja' ? `${title}を見る` : title;
  }
}

export const MyBrandConceptMovie = tsx
  .ofType<
    MyBrandConceptMovieProps,
    MyBrandConceptMovieEmits,
    MyBrandConceptMovieScopedSlots
  >()
  .convert(MyBrandConceptMovieRef);
