import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBasicInfoWithLocation } from './-MyBrandHotel';
import { HIconLink, HExpandButton } from '~/components';

export interface MainThemeListProps {
  hotels: HotelBasicInfoWithLocation[];
}

export interface MainThemeListEmits {}

export interface MainThemeListScopedSlots {}

@Component<MainThemeListRef>({
  name: 'MainThemeList',
  render() {
    const { hotels } = this;

    const countString = this.$t('value.numOfHotels', {
      count: hotels.length,
    }) as string;

    return (
      <div staticClass="my-hotels__list-wrapper__inner">
        <HExpandButton
          label={countString}
          staticClass="my-hotels__list-wrapper__title">
          <ul
            staticClass="my-hotels__list"
            class={{
              'my-hotels__list--less-than-4': hotels.length < 4,
              'my-hotels__list--less-than-2': hotels.length < 2,
            }}>
            {hotels.map((hotel) => {
              const { location } = hotel;

              return (
                <li key={hotel.slug} staticClass="my-hotels__list__item">
                  <HIconLink
                    staticClass="my-hotels__list__item__link"
                    icon="triangle-right"
                    hiddenExternalIcon
                    to={location.to}
                    href={location.href}
                    target={location.target}
                    rel={location.rel}>
                    {hotel.name}
                  </HIconLink>
                </li>
              );
            })}
          </ul>
        </HExpandButton>
      </div>
    );
  },
})
export default class MainThemeListRef
  extends Vue
  implements MainThemeListProps {
  @Prop({ type: Array, required: true })
  readonly hotels!: HotelBasicInfoWithLocation[];
}

export const MainThemeList = tsx
  .ofType<MainThemeListProps, MainThemeListEmits, MainThemeListScopedSlots>()
  .convert(MainThemeListRef);
