import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { HotelBrandDetail } from '~/schemes';
import { HImg, HBtn } from '~/components';
import { limitArrayLength } from '~/helpers';

export interface MyBrandNewsProps {
  data: HotelBrandDetail;
}

export interface MyBrandNewsEmits {}

export interface MyBrandNewsScopedSlots {}

@Component<MyBrandNewsRef>({
  name: 'MyBrandNews',
  render() {
    const { brand } = this;
    const { news } = brand;

    return (
      <div staticClass="my-news">
        <h2 staticClass="my-news__title">{this.$t('label.notice')}</h2>
        <ul staticClass="my-news__contents">
          {limitArrayLength(news, 4).map(({ link, heading, thumbnail }) => (
            <li staticClass="my-news__item">
              <a href={link} staticClass="my-news__item__link">
                {/* 画像があれば表示し、なければグレーの四角形を表示 */}
                {thumbnail?.public_id ? (
                  <HImg
                    staticClass="my-news__item__image"
                    src={this.$res.img(thumbnail?.derived.main.url!)}
                  />
                ) : (
                  <div class={['my-news__item__empty-image']}></div>
                )}
              </a>
              {heading && (
                <h3
                  staticClass="my-news__item__caption"
                  v-wysiwyg={heading}></h3>
              )}
            </li>
          ))}
        </ul>
        <HBtn
          staticClass="my-news__action"
          to={`/${this.$language.current}/sp/?brand=${this.brand.slug}`}>
          {this.$t('action.forFullList')}
        </HBtn>
      </div>
    );
  },
})
export default class MyBrandNewsRef extends Vue implements MyBrandNewsProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;

  get brand() {
    return this.data;
  }
}

export const TypedMyBrandNews = tsx
  .ofType<MyBrandNewsProps, MyBrandNewsEmits, MyBrandNewsScopedSlots>()
  .convert(MyBrandNewsRef);
