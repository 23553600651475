import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {
  MyBrandSectionHeader,
  MyBrandFeature,
  MyBrandHotel,
  MyBrandPolicy,
  MyBrandNews,
} from '../../-components';
import {
  sectionId,
  HotelBrandDetail,
  HotelBasicInfo,
  HotelMasterList,
} from '~/schemes';

export interface MyBrandSectionProps {
  data: HotelBrandDetail;
  sectionId: sectionId;
  hotels: HotelBasicInfo[];
  groupedByBrandType: HotelMasterList[];
}

export interface MyBrandSectionEmits {}

export interface MyBrandSectionScopedSlots {}

@Component<MyBrandSectionRef>({
  name: 'MyBrandSection',
  render() {
    const { brand, sectionId, hotels, groupedByBrandType } = this;
    const { policy } = brand;

    return (
      <section id={sectionId} staticClass="my-section">
        {(() => {
          if (sectionId === 'header') {
            return <MyBrandSectionHeader data={brand} />;
          }
          if (sectionId === 'features') {
            return <MyBrandFeature data={brand} />;
          }
          if (sectionId === 'policy' && !!policy) {
            return <MyBrandPolicy policy={policy} />;
          }
          if (sectionId === 'hotels') {
            return (
              <MyBrandHotel
                hotels={hotels}
                groupedByBrandType={groupedByBrandType}
                data={brand}
              />
            );
          }
          if (sectionId === 'news') {
            return <MyBrandNews data={brand} />;
          }
        })()}
      </section>
    );
  },
})
export default class MyBrandSectionRef
  extends Vue
  implements MyBrandSectionProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;
  @Prop({ type: String, required: true }) readonly sectionId!: sectionId;
  @Prop({ type: Array, required: true }) readonly hotels!: HotelBasicInfo[];
  @Prop({ type: Array, required: true })
  readonly groupedByBrandType!: HotelMasterList[];

  get brand() {
    return this.data;
  }
}

export const TypedMyBrandSection = tsx
  .ofType<MyBrandSectionProps, MyBrandSectionEmits, MyBrandSectionScopedSlots>()
  .convert(MyBrandSectionRef);
