import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BrandViewRoot from '../../../-index';
import { MyBrandConceptMovie } from './MyBrandConceptMovie';
import { HotelBrandDetail } from '~/schemes';
import { BrandConceptMovie } from '~/schemes/brand';

import { HSpriteAnimation } from '~/components';

export interface MyBrandSectionHeaderProps {
  data: HotelBrandDetail;
}

export interface MyBrandSectionHeaderEmits {}

export interface MyBrandSectionHeaderScopedSlots {}

@Component<MyBrandSectionHeaderRef>({
  name: 'MyBrandSectionHeader',
  inject: ['brandViewRoot'],
  render() {
    const {
      motionLogo,
      brand,
      computedHotels: hotels,
      conceptMovie,
      brandName,
      // isInviewEnabled,
    } = this;
    const { catchCopy, mainLogo, keyVisual, lead } = brand;

    const countString = this.$t('value.numOfHotels', {
      count: hotels.length,
    }) as string;

    // const viewProps = isInviewEnabled
    //   ? {
    //       directives: [
    //         {
    //           name: 'inview',
    //           value: {
    //             in: () => {
    //               this.brandViewRoot.pageTopScrollActiveSectionId = 'header';
    //             },
    //             rootMargin: '-100px',
    //           },
    //         },
    //       ],
    //     }
    //   : {};

    if (this.$theme.is('hoshinoya')) {
      return (
        // <div staticClass="my-header" {...viewProps}>
        <div staticClass="my-header">
          <div staticClass="my-header__inner">
            <h1 staticClass="my-header__logo">
              {!motionLogo ? (
                <img
                  key="static-logo"
                  staticClass="my-header__logo__img"
                  // オープニングアニメーション
                  // class={{
                  //   'my-header__logo__img--loaded': this.headLogoLoaded,
                  // }}
                  alt={brand.name}
                  src={this.$res.img(`/brands/${brand.slug}/logo-header.svg`)}
                />
              ) : (
                <HSpriteAnimation
                  key="sprite-logo"
                  staticClass="my-header__logo my-header__logo--sprite"
                  src={motionLogo.url}
                  fallback={motionLogo.fallback}
                  width={motionLogo.width}
                  height={motionLogo.height}
                  fps={motionLogo.fps}
                  duration={motionLogo.duration}>
                  {brand.name}
                </HSpriteAnimation>
              )}
            </h1>
            <div staticClass="my-header__catch-copy" v-wysiwyg={catchCopy} />
            <div
              staticClass="my-header__num-of-hotels theme-secondary-text-color"
              domPropsInnerHTML={countString}
            />

            <div
              staticClass="my-header__key_visual"
              style={{
                backgroundImage: `url(${this.$res.img(keyVisual.image)})`,
              }}
            />
            {/* アニメーション
            <div class="my-header__logo__key_visual__shutter-up"></div>
            <div class="my-header__logo__key_visual__shutter-down"></div> */}
          </div>

          <div staticClass="my-header__lead" v-wysiwyg={lead} />

          {conceptMovie && <MyBrandConceptMovie conceptMovie={conceptMovie} />}
        </div>
      );
    } else {
      return (
        <div staticClass="my-header">
          <h1 staticClass="my-header__title">
            {!motionLogo ? (
              <img
                key="static-logo"
                staticClass="my-header__logo"
                src={this.$res.img(mainLogo.url)}
                alt={brand.name}
              />
            ) : (
              <HSpriteAnimation
                key="sprite-logo"
                staticClass="my-header__logo my-header__logo--sprite"
                src={motionLogo.url}
                fallback={motionLogo.fallback}
                width={motionLogo.width}
                height={motionLogo.height}
                fps={motionLogo.fps}
                duration={motionLogo.duration}>
                {brand.name}
              </HSpriteAnimation>
            )}
          </h1>

          {this.isOtherTheme() && (
            <div staticClass="my-header__brand-name" v-wysiwyg={brandName} />
          )}

          <div staticClass="my-header__catch-copy" v-wysiwyg={catchCopy} />
          <div
            staticClass="my-header__num-of-hotels theme-secondary-text-color"
            domPropsInnerHTML={countString}
          />
          <div
            staticClass="my-header__key_visual"
            style={{
              backgroundImage: `url(${this.$res.img(keyVisual.image)})`,
            }}>
            <div staticClass="my-header__key_visual__alt">{keyVisual.alt}</div>
          </div>

          <div staticClass="my-header__lead" v-wysiwyg={lead} />
          {/* ブランドコンセプトムービー */}
          {conceptMovie && <MyBrandConceptMovie conceptMovie={conceptMovie} />}
        </div>
      );
    }
  },
})
export default class MyBrandSectionHeaderRef
  extends Vue
  implements MyBrandSectionHeaderProps {
  readonly brandViewRoot!: BrandViewRoot;
  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;

  get brand() {
    return this.data;
  }

  get mainLogo() {
    return this.data.mainLogo;
  }

  get motionLogo() {
    const motionLogo = this.data.mainLogo.sprite;
    if (motionLogo) {
      const { url, fallback, webp } = motionLogo;
      return {
        ...motionLogo,
        url: webp ? this.$res.webp(url) : this.$res.img(url),
        fallback: fallback && this.$res.img(fallback),
      };
    }
  }

  get computedHotels() {
    return this.hotels
      .map((hotel) => {
        return {
          ...hotel,
          location: this.$hotel.toNavigationProps(hotel)!,
        };
      })
      .filter((h) => !!h.location);
  }

  get hotels() {
    return this.brandViewRoot.hotels;
  }

  get conceptMovie(): BrandConceptMovie | null {
    return this.data.conceptMovie;
  }

  /** コンセプトムービータイトル */
  get title(): string {
    if (!this.conceptMovie) return '';
    const { title } = this.conceptMovie;
    if (!title) return '';
    return this.$language.current === 'ja' ? `${title}を見る` : title;
  }

  get brandName() {
    return this.$theme.is('uniquehotels')
      ? `そのほかの</br>個性的な宿泊施設`
      : '日帰り施設';
  }

  /** テーマが「そのほかの個性的なホテル」または「日帰り施設」かどうかを返却 */
  private isOtherTheme(): boolean {
    return this.$theme.is('uniquehotels') || this.$theme.is('daytrip');
  }

  // // アニメーションの設定
  // private headLogoLoaded: boolean = false;
  // /** 星のやの時、OPの発火に関するのプロパティを設置 */
  // get isInviewEnabled() {
  //   return this.$theme.is('hoshinoya');
  // }
}

export const TypedMyBrandSectionHeader = tsx
  .ofType<
    MyBrandSectionHeaderProps,
    MyBrandSectionHeaderEmits,
    MyBrandSectionHeaderScopedSlots
  >()
  .convert(MyBrandSectionHeaderRef);
