
import Vue from 'vue';
import { Component } from 'nuxt-property-decorator';
import BrandViewRoot from '../-index';
import { MyBrandSection } from './-components';
import { sectionId, BrandType, HotelMasterList } from '~/schemes';

@Component<MyView>({
  inject: ['brandViewRoot'],
  // アニメーション実装で使った部分
  // mounted() {
  // if (!this.USE_MOTION_LOGO) {
  //   loadImageSize(this.headLogoUrl)
  //     .then(() => {
  //       this.headLogoLoaded = true;
  //     })
  //     .catch(() => {
  //       // ロード失敗してもとりあえず読み込み済みフラグつけとく
  //       this.headLogoLoaded = true;
  //     });
  // }
  // 背景の実装で使う予定
  // if (this.$theme.is('hoshinoya')) {
  //   if (process.client) gsap.registerPlugin(ScrollTrigger);
  //   ScrollTrigger.create({
  //     trigger: '#features',
  //     start: 'top-=20% center',
  //     end: 'bottom center',
  //     onEnter: () => {
  //       this.brandViewRoot.pageTopScrollActiveSectionId = 'features';
  //     },
  //   });
  // }
  // },
  // アニメーション実装で使った部分
  // watch: {
  //   brandSlug: {
  //     handler() {
  //       if (process.server) return;
  //       // 施設Slug変更時にアニメーションを開始する
  //       this.$nextTick(this.dispatchIntroAnimation);
  //     },
  //     immediate: true,
  //   },
  // },
  // アニメーション実装で使った部分
  // asyncData({ $env, $language, $cookies }) {
  //   const USE_MOTION_LOGO_COOKIE_NAME = 'H-USED-HOTEL-MOTION-LOGO';
  //   const motionLogoIsUsed =
  //     $cookies.get(USE_MOTION_LOGO_COOKIE_NAME) === 'used';
  //   const USE_MOTION_LOGO = $language.current === 'ja' && !motionLogoIsUsed;
  //   if (USE_MOTION_LOGO) {
  //     $cookies.set(USE_MOTION_LOGO_COOKIE_NAME, 'used');
  //   }

  //   return {
  //     USE_MOTION_LOGO,
  //     animLogoEnded: !USE_MOTION_LOGO,
  //     headLogoLoaded: USE_MOTION_LOGO,
  //   };
  // },
  asyncData() {
    const USE_MOTION_LOGO = true;
    return { USE_MOTION_LOGO };
  },
  // アニメーション実装で使った部分
  // head() {
  //   const { motionLogo } = this;
  //   const motionLogoUrl = motionLogo && motionLogo.url;
  //   const link = motionLogoUrl
  //     ? [
  //         {
  //           hid: motionLogoUrl,
  //           rel: 'preload',
  //           href: motionLogoUrl,
  //           as: 'image',
  //         },
  //       ]
  //     : [];
  //   return {
  //     link,
  //   };
  // },
  head() {
    const { motionLogo } = this;
    const motionLogoUrl = motionLogo && motionLogo.url;
    const link = motionLogoUrl
      ? [
          {
            hid: motionLogoUrl,
            rel: 'preload',
            href: motionLogoUrl,
            as: 'image',
          },
        ]
      : [];
    return {
      link,
    };
  },
  render() {
    const { brand, hotels, groupedByBrandType } = this;

    return (
      <div
        staticClass="my-brand-index-view"
        class={`my-brand-index-view--${this.$theme.current.name}`}>
        {/* class={[
          this.classes,
          `my-brand-index-view--${this.$theme.current.name}`,
        ]} */}
        {/* <div staticClass="my-bg my-bg--first-view"></div> */}
        <div staticClass="my-bg my-bg--feature">
          {/* @TODO 時間でふわっと切り替え */}
          <div staticClass="my-bg--feature__img1"></div>
          <div staticClass="my-bg--feature__img2"></div>
          <div staticClass="my-bg--feature__img3"></div>
        </div>

        <div staticClass="my-sections">
          {this.sections.map((sectionId) => {
            return (
              <MyBrandSection
                data={brand}
                sectionId={sectionId}
                hotels={hotels}
                groupedByBrandType={groupedByBrandType}
              />
            );
          })}
        </div>
      </div>
    );
  },
})
export default class MyView extends Vue {
  readonly brandViewRoot!: BrandViewRoot;

  get headLogoUrl() {
    if (this.$theme.current.name === 'hoshinoya') {
      return this.$res.img(this.brand.mainLogo.url);
    }
    return '';
  }

  get brand() {
    return this.brandViewRoot.brand;
  }

  get hotels() {
    return this.brandViewRoot.hotels;
  }

  /** 横断検索マスタから施設マスタを取得 */
  get crossSearch() {
    return this.brandViewRoot.crossSearch.hotelMasterList;
  }

  /** slugと一致するサブブランドの横断検索マスタの施設マスタを取得 */
  get groupedByBrandType() {
    const data = this.crossSearch;
    const groupedByBrandType: HotelMasterList[] = [];
    Object.values(data).forEach((item) => {
      const { brandType } = item;
      const brandTypeName = BrandType[brandType];

      if (brandTypeName === this.brandViewRoot.brand.slug) {
        groupedByBrandType.push(item);
      }
    });
    return groupedByBrandType;
  }

  get motionLogo() {
    const motionLogo = this.brand.mainLogo.sprite;
    if (motionLogo) {
      const { url, fallback, webp } = motionLogo;
      return {
        ...motionLogo,
        url: webp ? this.$res.webp(url) : this.$res.img(url),
        fallback: fallback && this.$res.img(fallback),
      };
    }
  }

  /** 各ブランドで存在するデータによってセクションIDを取得 */
  get sections(): sectionId[] {
    const sectionId: sectionId[] = [];
    sectionId.push('header');

    if (this.brandViewRoot.brand.features.items.length !== 0) {
      sectionId.push('features');
    }
    if (this.brandViewRoot.brand.policy) {
      sectionId.push('policy');
    }
    if (this.brandViewRoot.brand.linkedHotels?.length !== 0) {
      sectionId.push('hotels');
    }
    if (this.brandViewRoot.brand.news.length !== 0) {
      sectionId.push('news');
    }
    return sectionId;
  }

  // ここからアニメーションの設定
  // readonly USE_MOTION_LOGO!: boolean;
  // /** アニメーション */
  // private animLogoEnded: boolean = false;
  // private topHoshinoyaDuringAnimation: boolean = true;
  // private headLogoLoaded: boolean = false;
  // private _introAnimationTimeline?: gsap.core.Timeline;

  // get classes() {
  //   const { animLogoEnded } = this;
  //   const scrollActiveSectionId = this.brandViewRoot
  //     .pageTopScrollActiveSectionId;
  //   return {
  //     'my-brand-index-view--anim-logo-ended': animLogoEnded,
  //     'my-brand-index-view--anim-logo-not-ended': !animLogoEnded,
  //     [`my-brand-index-view--hoshinoya--${scrollActiveSectionId}`]: scrollActiveSectionId,
  //     'my-brand-index-view--hoshinoya--top-during-animation': this
  //       .topHoshinoyaDuringAnimation,
  //   };
  // }
  // /** イントロアニメーション破棄 */
  // private killIntroAnimationTimeline() {
  //   if (this._introAnimationTimeline) {
  //     this._introAnimationTimeline.kill();
  //     this._introAnimationTimeline = undefined;
  //   }
  // }
  // private dispatchIntroAnimation() {
  //   this.killIntroAnimationTimeline();
  //   if (this.$theme.is('hoshinoya')) {
  //     const timeline = gsap
  //       .timeline()
  //       .to('.my-header__logo', {
  //         delay: 1.5,
  //         opacity: 1,
  //         duration: 0.5,
  //         ease: 'Expo.easeInOut',
  //       })
  //       .to(
  //         '.my-header__logo',
  //         {
  //           delay: 1.5,
  //           y: 0,
  //           duration: 1.5,
  //           ease: 'Expo.easeInOut',
  //         },
  //         'openTiming',
  //       )
  //       .to(
  //         '.my-header__logo__key_visual__shutter-up',
  //         {
  //           delay: 2.5,
  //           scaleY: 0,
  //           duration: 1.5,
  //           ease: 'Expo.easeInOut',
  //         },
  //         'openTiming',
  //       )
  //       .to(
  //         '.my-header__logo__key_visual__shutter-down',
  //         {
  //           delay: 2.5,
  //           scaleY: 0,
  //           duration: 1.5,
  //           ease: 'Expo.easeInOut',
  //         },
  //         'openTiming',
  //       );
  //     this._introAnimationTimeline = timeline;

  //     timeline.then(() => {
  //       this.killIntroAnimationTimeline();
  //     });
  //   }
  // }
  // ここまでアニメーションの設定
}
