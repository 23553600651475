import * as tsx from 'vue-tsx-support';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';
import type { SwiperOptions } from 'swiper';
import { HotelBasicInfoWithLocation } from '../MyBrandHotel';
import { HHotelPriceInfo, HIconLink, HIcon } from '~/components';
import {
  HotelBasicInfo,
  HotelBrandDetail,
  initialDisplayHotel,
  INITIAL_DISPLAY_HOTELS,
} from '~/schemes';

interface SwiperElement extends HTMLElement {
  swiper: Swiper;
}

export interface MyBrandMapCardProps {
  data: HotelBrandDetail;
  hotelBasicInfoList: HotelBasicInfoWithLocation[];
  selectedHotelId: string;
}

export interface MyBrandMapCardEmits {
  onChangeHotelBasicInfo: HotelBasicInfo;
}

export interface MyBrandMapCardScopedSlots {}

@Component<MyBrandMapCardRef>({
  name: 'MyBrandMapCard',
  render() {
    const { brand, hotelBasicInfoList, isLoadedExchangeRates } = this;

    return (
      <div staticClass="my-map-card">
        <div staticClass="my-map-card__contents">
          <div staticClass="h-simple-pagination">
            {isLoadedExchangeRates && (
              <div
                staticClass="swiper-container"
                key="main"
                ref="main"
                {...{
                  directives: [
                    {
                      name: 'swiper',
                      arg: 'mainSwiper',
                      value: this.mainOptions,
                    },
                  ],
                }}>
                <div staticClass="swiper-wrapper">
                  {hotelBasicInfoList.map((hotel) => (
                    <div staticClass="swiper-slide">
                      {hotel.introduction.image && (
                        <div staticClass="my-map-card__contents__image">
                          <img src={hotel.introduction.image.derived.md.url} />
                        </div>
                      )}

                      <div staticClass="my-map-card__contents__details">
                        <div staticClass="my-map-card__contents__details__title">
                          {hotel.name}
                        </div>

                        {hotel.introduction.catchCopy && (
                          <div staticClass="my-map-card__contents__details__catch-copy">
                            {hotel.introduction.catchCopy}
                          </div>
                        )}

                        {hotel.lowestPrice && (
                          <div staticClass="my-map-card__contents__details__price">
                            <HHotelPriceInfo
                              brandSlug={brand && brand.slug}
                              selectedHotelInfo={hotel}
                            />
                          </div>
                        )}

                        {hotel.location && (
                          <HIconLink
                            staticClass="my-map-card__contents__details__link"
                            icon="arrow-right"
                            hiddenExternalIcon
                            href={hotel.location.href}
                            to={hotel.location.to}
                            target={hotel.location.target}
                            rel={hotel.location.rel}>
                            {this.$t('label.details')}
                          </HIconLink>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {!this.isNarrow && (
                  <div staticClass="swiper-button">
                    <HIcon
                      staticClass="swiper-button-prev"
                      name="keyboard-arrow-left"
                    />
                    <HIcon
                      staticClass="swiper-button-next"
                      name="keyboard-arrow-right"
                    />
                  </div>
                )}
                <div class="swiper-pagination"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
})
export default class MyBrandMapCardRef
  extends Vue
  implements MyBrandMapCardProps {
  @Prop({ type: Object, required: true }) readonly data!: HotelBrandDetail;
  @Prop({ type: Array, required: true })
  readonly hotelBasicInfoList!: HotelBasicInfoWithLocation[];

  @Prop({ type: String }) readonly selectedHotelId!: string;

  $refs!: {
    main: SwiperElement;
  };

  mainSwiper?: Swiper;

  private internalSwiperIndex: number = 0;

  // カードの初期表示設定
  get initial() {
    return this.hotelBasicInfoList.findIndex(
      (val) =>
        val.ygetsId ===
        INITIAL_DISPLAY_HOTELS[this.$theme.current.name as initialDisplayHotel],
    );
  }

  get selectedHotelInfo() {
    return this.$hotel.all.find((val) => val.ygetsId === this.selectedHotelId);
  }

  get brand() {
    return this.data;
  }

  get mainOptions(): SwiperOptions {
    if (this.isNarrow) {
      return {
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'fraction',
        },
        spaceBetween: 12,
        loop: true,
        centeredSlides: true,
        on: {
          slideChange: () => {
            this.syncIndex();
          },
        },
        initialSlide: this.initial,
        slidesPerView: 'auto',
      };
    }

    return {
      lazy: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'fraction',
      },
      spaceBetween: 12,
      loop: true,
      centeredSlides: true,
      on: {
        slideChange: () => {
          this.syncIndex();
        },
      },
      initialSlide: this.initial,
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };
  }

  /** swiperでactiveになっている施設の情報を取得 */
  get currentHotelBasicInfo() {
    return this.hotelBasicInfoList[this.currentSwiperIndex];
  }

  /** swiperでactiveになっている要素のindexを取得 */
  get currentSwiperIndex() {
    return this.internalSwiperIndex;
  }

  /** internalSwiperIndexが変更されたとき、変更後のカードのhotelIdを親へ送信 */
  set currentSwiperIndex(currentSwiperIndex) {
    if (this.internalSwiperIndex !== currentSwiperIndex) {
      this.internalSwiperIndex = currentSwiperIndex;

      this.$emit('changeHotelBasicInfo', this.currentHotelBasicInfo.ygetsId);
    }
  }

  get isNarrow() {
    return this.$mq.match.narrow;
  }

  /** HPrice.tsxでSSRでの情報取得が完了しているか判定 */
  get isLoadedExchangeRates() {
    return this.$commons.exchangeRates.length !== 0;
  }

  /** マップで選択された施設のhotelIdを監視 */
  @Watch('selectedHotelId')
  onSlideToIndexChange(selectedHotelId: string) {
    if (this.mainSwiper) {
      this.internalSwiperIndex = this.hotelBasicInfoList.findIndex(
        (v) => v.ygetsId === selectedHotelId,
      );
      // マップで選択された施設のindexを指定し、スライドする
      this.mainSwiper.slideTo(this.internalSwiperIndex);
    }
  }

  private syncIndex() {
    const { mainSwiper } = this;
    if (!mainSwiper) return;
    this.currentSwiperIndex = mainSwiper.realIndex;
  }
}

export const TypedMyBrandMapCard = tsx
  .ofType<MyBrandMapCardProps, MyBrandMapCardEmits, MyBrandMapCardScopedSlots>()
  .convert(MyBrandMapCardRef);
